import { ref } from "vue";
import { defineStore } from "pinia";
import { DaysToSeconds } from "@ilihub/time";
import { useContentApi } from "~/composables/api/useContentApi";
import { getMonthsFromPeriod } from "~/utils/period";
import type { QueryParameters } from "~/utils/queryParams";

const DEFAULT_SEGMENT = "1";

export const useDiscountStore = defineStore(
  "discount",
  () => {
    const backendApiClient = useAppApi();
    const frontendApiClient = useFrontendApi();
    const contentApiClient = useContentApi();
    const nuxtApp = useNuxtApp();
    const { payload: nuxtPayload, static: nuxtStatic } = useNuxtApp();
    const { getCountry } = useCountry();

    const code = ref<string>("");
    const partnerContentOverride = ref<string>("");

    const partnerContentCode = computed(() => partnerContentOverride.value || code.value);

    const useAsyncPartnerContent = () =>
      useAsyncData(
        "partner-content",
        () => contentApiClient.fetchPartnerContentByDiscountCode(partnerContentCode.value),
        {
          getCachedData(key) {
            return nuxtPayload.data[key] || nuxtStatic.data[key];
          },
          watch: [code],
        },
      );

    async function getActiveSegment(): Promise<string> {
      if (!code.value) {
        return DEFAULT_SEGMENT;
      }

      try {
        const segmentData = await backendApiClient.getValidVoucherCodeDetails(code.value);
        return segmentData.segment ?? DEFAULT_SEGMENT;
      } catch (_) {
        return DEFAULT_SEGMENT;
      }
    }

    const useAsyncDiscount = () =>
      useAsyncData(
        "discount",
        async () => {
          const country = getCountry();

          if (!code.value) {
            return 0;
          }

          const { locale } = nuxtApp.$i18n;

          const activeSegment = await nuxtApp.runWithContext(() => getActiveSegment());

          if (activeSegment === DEFAULT_SEGMENT) {
            return 0;
          }

          const data = await nuxtApp.runWithContext(() =>
            frontendApiClient.getAvailableSubscriptionPlansFetch({
              country,
              locale: locale.value,
              segment: activeSegment,
            }),
          );

          const plans = data
            .filter((product) => product.type === "subscription")
            .sort((a, b) => getMonthsFromPeriod(b.period) - getMonthsFromPeriod(a.period));

          const plan = plans.find((plan) => plan.strike_price_raw !== null);

          if (!plan || !plan.strike_price_raw) {
            return 0;
          }

          return Math.round(((plan.strike_price_raw - plan.price_raw) / plan.strike_price_raw) * 100);
        },
        {
          getCachedData(key) {
            return nuxtPayload.data[key] || nuxtStatic.data[key];
          },
          watch: [code],
        },
      );

    function handleQueryParameters(queryParameters: QueryParameters) {
      if (queryParameters.sessionShouldReset()) {
        $reset();
      }

      code.value = queryParameters.getAndRemoveAll(["code", "sid", "partner", "coupon"]) ?? code.value;
      partnerContentOverride.value = queryParameters.getAndRemove("partnerOverride") ?? partnerContentOverride.value;
    }

    function $reset() {
      code.value = "";
      partnerContentOverride.value = "";
    }

    return {
      code,
      partnerContentOverride,
      useAsyncPartnerContent,
      useAsyncDiscount,
      getActiveSegment,
      handleQueryParameters,
      $reset,
    };
  },
  {
    persist: {
      pick: ["code", "partnerContentOverride"],
      storage: piniaPluginPersistedstate.cookies({
        maxAge: DaysToSeconds(1),
      }),
    },
  },
);
