import { ref } from "vue";
import { defineStore } from "pinia";
import { HoursToSeconds } from "@ilihub/time";
import appConfig from "~/app.config";
import type { UserDetails } from "~/types/backendApiClient";
import type { QueryParameters } from "~/utils/queryParams";

export const useTokenUserStore = defineStore(
  "tokenUser",
  () => {
    const { $pinia } = useNuxtApp();
    const authStore = useAuthStore($pinia);
    const userStore = useUserStore($pinia);
    const backendApiFrontendDomainClient = useFrontendApi();

    const tokenUser = ref<UserDetails | null>(null);
    const token = ref("");

    const { data: fetchUserDetailsData, execute: executeFetchUserDetails } =
      backendApiFrontendDomainClient.useGetUserDetails(token);

    async function fetchTokenUser() {
      await executeFetchUserDetails();

      if (!fetchUserDetailsData.value) {
        return;
      }

      tokenUser.value = fetchUserDetailsData.value;
    }

    function handleQueryParameters(queryParameters: QueryParameters, path: string) {
      if (path !== "/onboarding/checkout" && path !== "/account/confirm-email") {
        return;
      }

      if (queryParameters.has("token")) {
        // logout user if token is present
        authStore.$reset();
        userStore.$reset();
      }

      token.value = queryParameters.getAndRemove("token") || token.value;
    }

    function $reset() {
      tokenUser.value = null;
      token.value = "";
    }

    return {
      $reset,
      token,
      tokenUser,
      fetchTokenUser,
      handleQueryParameters,
    };
  },
  {
    persist: {
      pick: ["token"],
      storage: piniaPluginPersistedstate.cookies({
        maxAge: HoursToSeconds(appConfig.cookieMaxAgeH),
      }),
    },
  },
);
