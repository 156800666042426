import { StatusCodes } from "http-status-codes";
import { FetchError } from "ofetch";
import type {
  Request,
  RequestOptions,
  RefreshTokenParams,
  TokenResponse,
  LoginUserParams,
  SignInWithAppleParams,
  SignInWithGoogleParams,
} from "~/types/authApiClient";

export function useAuthApi() {
  const config = useRuntimeConfig();
  const { $pinia } = useNuxtApp();

  const { refreshAuthTokens } = useAuthStore($pinia);
  const { accessToken } = storeToRefs(useAuthStore($pinia));
  const baseUrl = config.public.backendApiAppDomainBaseUrl as string;
  const { fetch, useNuxtFetch, defaultOptions } = useRequest(baseUrl);

  async function getAccessToken(
    body: RefreshTokenParams | LoginUserParams | SignInWithGoogleParams | SignInWithAppleParams,
  ) {
    let client_id = config.public.oauthClientId as string;
    let client_secret: string | undefined = config.public.oauthClientSecret as string;

    if (body.grant_type === "sign_in_with_google") {
      client_id = config.public.siwgClientId;
      client_secret = undefined;
    }
    const response = await fetch<TokenResponse>("/v15/oauth/token", {
      method: "post",
      body: {
        ...body,
        client_id,
        client_secret,
      },
    });

    return {
      refreshToken: response.refresh_token,
      accessToken: response.access_token,
    };
  }

  function customFetch<T>(request: Request<T>, options?: RequestOptions<T>) {
    return fetch<T>(request, {
      ...options,
      headers: {
        Authorization: accessToken.value ? `Bearer ${accessToken.value}` : "",
        ...options?.headers,
      },
    });
  }

  async function fetchAuth<T>(request: Request<T>, options?: RequestOptions<T>) {
    try {
      return await customFetch<T>(request, options);
    } catch (err) {
      if (err instanceof FetchError && err.statusCode === StatusCodes.UNAUTHORIZED) {
        await refreshAuthTokens();
        return await customFetch<T>(request, options);
      }

      throw err;
    }
  }

  function useNuxtFetchAuth<T>(
    request: Parameters<typeof useNuxtFetch<T>>[0],
    options?: Parameters<typeof useNuxtFetch<T>>[1],
  ) {
    return useNuxtFetch<T>(request, {
      onRequest({ options }) {
        const headers: Headers = options.headers || new Headers();
        headers.set("Authorization", accessToken.value ? `Bearer ${accessToken.value}` : "");
        options.headers = headers;
      },
      onResponseError: async ({ response }) => {
        if (response.status === StatusCodes.UNAUTHORIZED) {
          await refreshAuthTokens();
        }
      },
      ...options,
      retryStatusCodes: [...defaultOptions.retryStatusCodes, StatusCodes.UNAUTHORIZED],
    });
  }

  return {
    getAccessToken,
    fetchAuth,
    useNuxtFetchAuth,
  };
}
