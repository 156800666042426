import type { MaybeRef } from "vue";
import type {
  GetSubscriptionPlansParams,
  SubscriptionPlan,
  UserDetails,
  ValidateEmailParams,
} from "~/types/backendApiClient";
import { UserDetailsSchema } from "~/types/backendApiClient";
import { getMonthsFromPeriod } from "~/utils/period";

export function useFrontendApi() {
  const config = useRuntimeConfig();
  const apiBaseUrl = config.public.backendApiFrontendDomainApiBaseUrl;
  const { fetch, useNuxtFetch } = useRequest(apiBaseUrl);

  function useGetAvailableSubscriptionPlans(
    query: MaybeRef<GetSubscriptionPlansParams | undefined>,
    immediate: boolean = false,
  ) {
    return useNuxtFetch<SubscriptionPlan[]>("/api/frontend/product", {
      query,
      immediate,
      transform: (res) =>
        res
          .filter((product) => product.type === "subscription")
          .sort((a, b) => getMonthsFromPeriod(b.period) - getMonthsFromPeriod(a.period)),
    });
  }

  function getAvailableSubscriptionPlansFetch(query: MaybeRef<GetSubscriptionPlansParams | undefined>) {
    return fetch<SubscriptionPlan[]>("/api/frontend/product", {
      query,
    });
  }

  function validateEmail(query: ValidateEmailParams) {
    return fetch("/api/frontend/user/validate-email", {
      query,
    });
  }

  function resetPassword(password: string, token: string) {
    return fetch("/api/frontend/user/reset-password", {
      method: "post",
      body: {
        token,
        new_password: password,
      },
    });
  }

  function deleteAccount(token: string) {
    return fetch("/api/frontend/user", {
      method: "delete",
      body: {
        token,
      },
    });
  }

  function unsubscribeNewsletter(unsubscribe_token: string) {
    return fetch("/api/frontend/user/newsletter-unsubscribe", {
      method: "delete",
      body: {
        unsubscribe_token,
      },
    });
  }

  function useGetUserDetails(userToken: MaybeRef<string>, immediate: boolean = false) {
    return useNuxtFetch<UserDetails>("/api/frontend/user/get-user-details", {
      query: {
        user_token: userToken,
      },
      immediate,
      transform: (res) => UserDetailsSchema.parse(res),
    });
  }

  function confirmEmail(token: string) {
    return fetch("/api/frontend/user/confirm-email", {
      method: "post",
      body: {
        token,
      },
    });
  }

  return {
    useGetAvailableSubscriptionPlans,
    getAvailableSubscriptionPlansFetch,
    validateEmail,
    resetPassword,
    deleteAccount,
    unsubscribeNewsletter,
    useGetUserDetails,
    confirmEmail,
  };
}
