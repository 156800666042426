import { defineStore } from "pinia";
import { DaysToSeconds } from "@ilihub/time";
import type { Voucher } from "~/types/voucher";

export const useVoucherStore = defineStore(
  "voucher",
  () => {
    const backendApiClient = useAppApi();
    const temporaryVoucherCode = ref<string>();

    function getValidVoucherCodeDetails(code: string): Promise<Voucher> {
      return backendApiClient.getValidVoucherCodeDetails(code);
    }

    function redeemCouponCode(code: string): Promise<void> {
      return backendApiClient.redeemCouponCode(code);
    }

    return {
      temporaryVoucherCode,
      getValidVoucherCodeDetails,
      redeemCouponCode,
    };
  },
  {
    persist: {
      pick: ["temporaryVoucherCode"],
      storage: piniaPluginPersistedstate.cookies({
        maxAge: DaysToSeconds(1),
      }),
    },
  },
);
